.button {
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to right, #00ed00 50%, #0a0a0a 50%) left !important;
    background-size: 201% !important;
    min-width: max-content !important;
  }
  
  .button:hover {
    background-position: right !important;
    color: white;
    transition: .25s ease-out;
  }
  
  .button:focus {
    box-shadow: none !important;
  }
  
  .sofitex-link {
    color: white;
    text-decoration: none;
  }
  
  .sofitex-link:hover {
    color: #00de00;
  }
  
  .sofitex-menu-link {
    color: white;
    text-decoration: none;
    transition: 0.5s;
    outline: none !important;
  }
  
  .sofitex-menu-link:hover {
    color: #00de00;
  }
  
  .sofitex-contact-link {
    color: black;
    text-decoration: none;
  }
  
  .sofitex-contact-link:hover {
    color: #00de00;
  }
  
  body {
    overflow-x: hidden;
    max-width: 100vw;
    min-width: 100vw;
  }
  
  html {
    overflow-x: hidden;
    max-width: 100vw;
  }
  
  .chakra-accordion__item :focus {
    box-shadow: none;
    font-weight: bold;
  }
  
  .chakra-accordion__item :active {
    box-shadow: none;
  }
  
  .chakra-accordion__item {
    box-shadow: none !important;
  }
  
  .chakra-accordion__item :hover {
    box-shadow: none !important;
  }
  
  .chakra-accordion__button :hover {
    font-weight: bold;
    box-shadow: none !important;
  }
  
  .chakra-accordion__button{
    box-shadow: none !important;
  }
  
  .chakra-accordion__panel {
    background-color: white;
  }
  
  .css-9ye717[aria-selected=true] {
    color: #00ed00 !important;
    box-shadow: none !important;
  }
  
  .upload-document-btn:hover {
    border-color: #00de00 !important;
    background-color: white !important;
  }
  
  .upload-document-btn:focus {
    box-shadow: none !important;
  }
  
  input[type=file]::file-selector-button {
    padding: 9px;
    border-radius: 8px;
    border: none;
    background-color: lightgray;
    transition: 0.5s;
    color: black;
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: rgb(192, 192, 192);
  }
  
  .chakra-checkbox.css-1uiwwan {
    display: flex;
    align-items: flex-start;
  }
  
  .chakra-checkbox__control.css-neh1pr {
    margin-top: 4px;
    box-shadow: none !important;
  }
  
  .burger-menu {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 9999;
    padding: 10px;
  }
  
  .burger-menu.open {
    display: inline-block;
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 5px;
    z-index: 9999;
    padding: 10px;
  }
  
  .burger-menu .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .burger-menu.open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 4px);
    transform: rotate(-45deg) translate(-4px, 4px);
  }
  
  .burger-menu.open .bar2 {
    opacity: 0;
  }
  
  .burger-menu.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
  }
  
  /* Menu */
  
  .menu {
    width: 100%;
    display: block;
    text-align: center;
    padding: 0px;
  }
  
  .menu ul {
    position: relative;
    top: 0px;
    font-size: 18px;
    padding: 0px 8px;
  }
  
  .menu li {
    list-style: outside none none;
    margin: 10px 0px;
    padding: 0;
    cursor: pointer;
    color: white;
  }
  
  .menu li:hover {
    color: #00de00;
  }
  
  .popup-content {
    margin-top: 50px !important;
  }
  
  .offers-btn {
    background-color: #e2e8f0;
    max-width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    word-wrap: break-word !important;
    word-break: break-all;
    transition: 0.6s;
  }
  
  .offers-btn:hover {
    background-color: black;
    color: #00ed00;
  }
  
  .btn-close:focus {
    box-shadow: none !important;
  }
  
  .description-title:hover {
    color:#00ed00;
  }
  
  #facebook-player-nwqgd {
    height: max-content !important;
  }
  
  iframe {
    max-width: 640px !important;
  }
  
  .bg-img1 {
    background-image: url("./images/Machine R974-Marcgio.png");
    background-size: cover;
    background-position: center center;
    /* box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.1); */
  }
  
  .dark-bg {
    background-color: rgba(0, 0, 0, 0.4);
  }